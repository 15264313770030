
export const Dictionaries = {
  returnCode: {
    1000: '成功请求并返回数据',
    2000: '参数不足',
    2001: '参数验证失败',
    3000: '数据异常',
    4000: '对象不存在',
    4001: '用户名重复',
    4002: '用户名或密码错误',
    4003: '状态错误',
    4004: '有子资源',
    4005: '数据重复',
    4006: '获取微信 openid 错误',
    4007: '解密数据失败',
    4008: '第三方服务错误',
    4009: '密码错误',
    4010: '账单已存在',
    4011: '配置项没有打开',
    4012: '库存不足',
    4013: '数量限制',
    4014: '记录存在',
    4015: '微信错误',
    4016: '微信凭证错误',
    4017: '展示商品金额错误',
    4018: '展示商品退回路径错误',
    4019: '商品计算库存错误',
    4020: '商品重复退款',
    4021: '等待浪潮审批',
    4022: '浪潮驳回'
  },
  risk_level: {
    1: 'C1',
    2: 'C2',
    3: 'C3',
    4: 'C4',
    5: 'C5'
  },
  lockup_period: {
    1: '年',
    2: '月',
    3: '日'
  }
}
