import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter);

// 路由懒加载
const Home = () => import('../views/home/Home.vue');
const Product = () => import('../views/product/Product.vue');
const ProductDetail = () => import('../views/product/detail/ProductDetail.vue');
const MyProductDetail = () => import('../views/product/detail/MyProductDetail.vue');
const Exam = () => import('../views/exam/Exam.vue');
const Mine = () => import('../views/mine/Mine.vue');
const Login = () => import(/* webpackChunkName: "group-login" */ '../views/login/Login.vue');
const LoginByCode = () => import(/* webpackChunkName: "group-login" */ '../views/login/LoginByCode.vue');
const LoginByPwd = () => import(/* webpackChunkName: "group-login" */ '../views/login/LoginByPwd.vue');
const RetrievePwd = () => import(/* webpackChunkName: "group-login" */ '../views/login/RetrievePwd.vue');
const InputUserInfo = () => import(/* webpackChunkName: "group-login" */ '../views/login/InputUserInfo.vue');

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/product',
    component: Product,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/exam',
    component: Exam,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mine',
    component: Mine,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/login',
    component: Login,
    meta: {
      keepAlive: true,
      requireAuth: false
    }
  },
  {
    path: '/loginByCode',
    name: '/loginByCode',
    component: LoginByCode,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/loginByPwd',
    name: '/loginByPwd',
    component: LoginByPwd,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/retrievePwd',
    name: '/retrievePwd',
    component: RetrievePwd,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/inputUserInfo',
    name: '/inputUserInfo',
    component: InputUserInfo,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/product/:id',
    component: ProductDetail,
    meta: {
      requireAuth: true,
      tabbarShow: 1,
    }
  },
  {
    path: '/myproduct/:id',
    component: MyProductDetail,
    meta: {
      requireAuth: true,
      tabbarShow: 1,
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // 需要权限校验
  if (to.meta.requireAuth === undefined && store.state.token === null) {
    store.commit('toggleTabbarShow', false);
    next('/login');
    return;
  } else if (to.meta.requireAuth && (store.state.token === ''|| store.state.token === undefined || store.state.token === null)){
    console.log(to.meta.requireAuth, store.state.token);
    store.commit('toggleTabbarShow', false);
    next('/login');
    return;
  } else if (to.meta.requireAuth && to.meta.tabbarShow===1){
    console.log('执行了跳转到商品详情的操作')
    store.commit('toggleTabbarShow', false);
  }

  next()
});

export default router
