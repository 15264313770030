import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import '@/assets/css/border.css'
import fastCLick from 'fastclick'
import http from '@/utils/http'
import { Dictionaries } from './common/AllConstant'
import {
  ActionSheet,
  Button,
  Cell,
  CellGroup,
  Col,
  Divider,
  DropdownItem,
  DropdownMenu,
  Field,
  Grid,
  GridItem,
  Icon,
  Image,
  List,
  NavBar,
  PullRefresh,
  Row,
  Switch,
  Tab,
  Tabbar,
  TabbarItem,
  Tabs,
  Toast,
  Popup,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Loading,
  Tag,
} from "vant";
import 'vant/lib/index.css'
// 引入全部样式
import 'vant/lib/index.less';

fastCLick.attach(document.body);

Vue.use(Tabbar).use(TabbarItem).use(NavBar).use(Tab).use(Tabs).use(Grid).use(GridItem).use(Divider).use(DropdownMenu)
  .use(DropdownItem).use(Switch).use(Button).use(List).use(Cell).use(CellGroup).use(PullRefresh).use(Row).use(Col)
  .use(Field).use(ActionSheet).use(Icon).use(Image).use(Toast).use(Popup).use(Checkbox).use(CheckboxGroup).use(Radio)
    .use(RadioGroup).use(Loading).use(Tag);

Vue.config.productionTip = false;

Vue.prototype.$http = http;
Vue.prototype.$Dictionaries = Dictionaries;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
