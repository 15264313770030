import router from "@/router";
import {Toast} from "vant";
import qs from "qs";
import store from '@/store'

export default {
  /**
   * 退出登录
   */
  logout(state) {
    state.token = null;
    // 删除本地token
    store.commit("deleteToken")
    store.commit('toggleTabbarShow', false);
    // 清除token
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    // 跳转到登录页面
    router.replace('/login')
      .catch(err => console.warn(err));
    Toast('退出成功')
  },

  /**
   * 登录
   * @param state
   * @param token
   */
  login(state, data) {
    Toast('登录成功');
    state.token = data.token;
    // 保存token到localStorage
    localStorage.setItem('token', data.token);
    // 保存用户信息
    localStorage.setItem('userInfo',qs.stringify(data))
    // 保存refreshToken到localStorage
    // localStorage.setItem('refreshToken', data.refreshToken);

    if (data.risk_level<2) {
      // 跳转到首页
      if (data.id_card) {
        router.replace('/exam')
            .catch(err => console.warn(err));
      } else {
        router.replace('/inputUserInfo')
            .catch(err => console.warn(err));
      }
    } else {
      // 切换tabbar为显示状态
      this.commit('toggleTabbarShow', true);
      // 跳转到首页
      router.replace('/')
          .catch(err => console.warn(err));
    }
  },

  /**
   * 切换tabbar显示
   * @param state
   * @param booleanVal
   */
  toggleTabbarShow(state, booleanVal) {
    state.tabbarShow = booleanVal
  },

  /**
   * 更新手机号
   * @param state
   * @param phone
   */
  updatePhone(state, phone) {
    state.phone = phone;
    localStorage.setItem("phone", phone)
  },

  /**
   * 更新token
   * @param state
   * @param token
   */
  updateToken(state, data) {
    state.token = data.token;
    localStorage.setItem("token", data.token)
    localStorage.setItem("refreshToken", data.refreshToken)
  },

  /**
   *
   * @param state
   * @param token
   * @param refreshToken
   */
  deleteToken(state) {
    state.token = null;
    localStorage.removeItem("token")
    localStorage.removeItem("refreshToken")
  }
};

